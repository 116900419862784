import React, { useState } from 'react';
import { Mutation } from '@apollo/client/react/components';
import { loader } from 'graphql.macro';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/client';

import { getNodes } from 'services/queryService';
import { getArticleFamilyOptions } from 'services/articleService';
import { PriceInput, TextInput, Button, TableWithOptions, Select, Icon } from 'components';
import { ButtonType } from 'components/Button';
import { updateLocalArticleVariables } from 'types/updateLocalArticle';
import { listLocalArticles_list_edges_node_LocalArticle as localArticle } from 'types/listLocalArticles';
import { OfferTemplateRouteParam } from 'localTypes';
import { appTheme } from 'theme';
import { listAllContainers_list_edges_node_Container as container } from 'types/listAllContainers';
import { ISelectOption } from '../../components/Inputs/Select';
import { ImportationType } from 'types/globalTypes';

const UPDATE_LOCAL_ARTICLE_MUTATION = loader('../../query/updateLocalArticle.gql');
const LIST_LOCAL_ARTICLES = loader('../../query/listLocalArticles.gql');
const LIST_CONTAINERS = loader('../../query/listAllContainers.gql');

enum QueryParamKeys {
    FAMILY = 'article.family',
}

function LocalArticle({
    item: {
        id,
        cashSystemCode,
        price: { amount },
        label,
        container,
        article: { importationType, lastUse }
    },
    offerType,
    index,
    containers
}: {
    item: localArticle;
    offerType: string;
    index: number;
    containers: container[];
}) {
    const [updatedPrice, setUpdatedPrice] = useState(amount);
    const [updatedCashSystemCode, setUpdatedCashSystemCode] = useState(cashSystemCode || '');
    const [updatedLabel, setUpdatedLabel] = useState(label || '');
    const [updatedContainer, setUpdatedContainer] = useState(container ? container.id : null);

    function hasUpdates() {
        const cashSystemCodeIsIdentical =
            (!cashSystemCode && !updatedCashSystemCode) || cashSystemCode === updatedCashSystemCode;
        const priceIsIdentical = parseFloat(amount) === parseFloat(updatedPrice);
        const containerIsIdentical = container ? container.id === updatedContainer : updatedContainer ? false : true;
        return !cashSystemCodeIsIdentical || !priceIsIdentical || !containerIsIdentical || label !== updatedLabel;
    }
    return (
        <Mutation mutation={UPDATE_LOCAL_ARTICLE_MUTATION}>
            {(updateLocalArticle: (param: Record<'variables', updateLocalArticleVariables>) => Promise<any>) => {
                const onChange = (value: ISelectOption) => {
                    setUpdatedContainer(value.id)
                };
                const onUpdate = () => {
                    updateLocalArticle({
                        variables: {
                            id,
                            cashSystemCode: updatedCashSystemCode,
                            price: `${updatedPrice}`,
                            label: updatedLabel,
                            idContainer: updatedContainer
                        },
                    });
                };
                return (
                    <>
                        <td style={{
                            width: '35%',
                            maxWidth: '250px',
                            overflow: 'hidden',
                            overflowWrap: 'break-word'
                        }}>
                            {offerType === OfferTemplateRouteParam.ClickCollect ? (
                                <LabelText>{label}</LabelText>
                            ) : (
                                <LabelTextInput inline onChange={setUpdatedLabel} value={updatedLabel} />
                            )}
                        </td>
                        {containers.length > 0 && (
                            <td style={{ width: '15%' }}>
                                <Select
                                    id='container-select'
                                    data={containers}
                                    selected={containers.find(({ id }) => updatedContainer !== undefined && id === updatedContainer)}
                                    onChange={(o: ISelectOption) => onChange(o)}
                                    disabled={false}
                                    hasError={false}
                                    width={150}
                                    color={appTheme.color.grey[6]}
                                />
                            </td>
                        )}
                        <td style={{ width: '10%' }}>
                            <TextInput
                                name={`cashSystemCode-input_row-${index}`}
                                inline
                                width={80}
                                onChange={setUpdatedCashSystemCode}
                                value={updatedCashSystemCode as string}
                            />
                        </td>
                        <td style={{ width: '10%' }}>
                            <LabelPriceInput name={`price-input_row-${index}`} onChange={setUpdatedPrice} value={updatedPrice} />
                        </td>
                        <td style={{ width: '10%' }}>
                            {importationType === ImportationType.Oscar
                                ? <Icon.Check width={18} height={16} color="#383838" />
                                : <Icon.Cross width={16} color="#383838" />
                            }
                        </td>
                        <td style={{ width: '15%' }}>
                            {lastUse}
                        </td>
                        {hasUpdates() && (<td style={{ width: '5%' }}>
                            <ButtonWrapper>
                                {hasUpdates() && (
                                    <Button id={`validation-button_row-${index}`} inline onClick={onUpdate}
                                            display={ButtonType.VALIDATION}>
                                        OK
                                    </Button>
                                )}
                            </ButtonWrapper>
                        </td>)}
                    </>
                );
            }}
        </Mutation>
    );
}

interface IProps {
    idHolding: string;
    idPos: string;
    offerType: string;
}

const LocalCatalogTable = ({ idHolding, idPos, offerType }: IProps) => {
    const { t } = useTranslation();
    const containersQueryVariables = {
        querySearch: [{ key: 'idHolding', operator: '=', value: idHolding }],
    };

    const { data, loading } = useQuery(LIST_CONTAINERS, {
        variables: containersQueryVariables,
    });

    const containers = !loading ? getNodes(data.list) : [];

    if (containers.length > 0) {
        containers.unshift({
            id: null,
            label: t('schema:localArticle.noContainer')
        })
    }

    const renderLine = (item: localArticle, offerType: string, index: number, containers: any[]) => {
        return (
            <tr style={{ flex: 1 }} key={item.id}>
                <LocalArticle item={item} offerType={offerType} index={index} containers={containers} />
            </tr>
        );
    };
    const defaultQueryVariables = {
        idOffer: '',
        withOfferItems: false,
        order: 'family,label',
        querySearch: [
            { key: 'idPos', value: idPos },
            { key: 'deleted', value: 'false' },
        ],
    };
    return (
        <TableWithOptions
            renderLine={(item: localArticle, index: number) => renderLine(item, offerType, index, containers)}
            headers={getHeaders(t, containers.length > 0)}
            fetchPolicy="cache-and-network"
            query={LIST_LOCAL_ARTICLES}
            variables={defaultQueryVariables}
            searchPlaceholder={t('app:placeholder.search.article')}
            tags={{
                [QueryParamKeys.FAMILY]: getArticleFamilyOptions(),
            }}
            groupBy={{
                key: 'article.family',
                options: getArticleFamilyOptions(true, t(`schema:article.familyOption.OTHER`) || ''),
            }}
        />
    );
};

const getHeaders = (t: any, hasContainers: boolean) => 
    
    hasContainers ? 
    [{
        key: 'label',
        displayName: t('schema:article.label'),
    },
    hasContainers
        ? {
            key: 'container',
            displayName: t('schema:localArticle.container'),
        }
        : null,
    {
        key: 'cashSystemCode',
        displayName: t('schema:localArticle.cashSystemCode'),
    },
    {
        key: 'price.amount',
        displayName: t('schema:localArticle.price.amount'),
    },
    {
        key: 'fromOscar',
        displayName: t('schema:localArticle.fromOscar'),
    },
    {
        key: 'lastUse',
        displayName: t('schema:localArticle.lastUse'),
    },
    {
        key: 'action',
        displayName: '',
    }]
    :
    [{
        key: 'label',
        displayName: t('schema:article.label'),
    },

    {
        key: 'cashSystemCode',
        displayName: t('schema:localArticle.cashSystemCode'),
    },
    {
        key: 'price.amount',
        displayName: t('schema:localArticle.price.amount'),
    },
    {
        key: 'action',
        displayName: '',
    }];

const ButtonWrapper = styled.div`
    width: 60px;
`;

const LabelTextInput = styled(TextInput)`
    width: 100%;
`;

const LabelText = styled.div`
    text-align: left;
    font-size: ${({ theme }) => theme.typography.fontSizeS}px;
    color: ${({ theme }) => theme.color.navy};
    font-weight: 600;
`;

const LabelPriceInput = styled(PriceInput)`
    text-align: left;
    font-size: ${({ theme }) => theme.typography.fontSizeS}px;
    color: ${({ theme }) => theme.color.navy};
    font-weight: 600;
`;

export default LocalCatalogTable;
