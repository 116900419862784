import { MultiStep } from 'components';
import React, { useState } from 'react';
import appRoutes from 'Router/routes';
import * as Yup from 'yup';
import StepOne from './StepOne';
import StepThree from './StepThree';
import StepTwo from './StepTwo';

import { useTranslation } from 'react-i18next';
import { MarketingTypeNew } from 'types/globalTypes';
import { ButtonType } from 'components/Button';
import { FormikProps, FormikValues } from 'formik';
import { Option } from 'react-select';

type KeyContent = { key: string; content: string; __typename?: string; };
type SavedData = Record<string, any>;
type LanguageStringContent = Record<string, string>;
type LanguageArrayContent = Record<string, KeyContent[]>;

export type formData = {
    languages: string[];
    holdings: Option[];
    type: string;
    title: string;
    idDraft: string | undefined;
    isDraft: boolean;
    frArticleTitle: string;
    frArticleResume: string;
    enArticleTitle: string;
    enArticleResume: string;
    publicationStartDate: Date | null;
    publicationEndDate: Date | null;
    image: { id: string | null; hash?: string; path?: string; created?: string }[];
    mustHavePublicationEndDate: boolean;
    contentFR: KeyContent[] | [];
    contentEN: KeyContent[] | [];
    linkToExternalPage: string | null;
};

interface IAddOrEditForm {
    onSubmit: (props: any) => void;
    isModeEdit: boolean;
    onSubmitDraft: (props: any) => void;
    draftLabel: any;
    savedData?: SavedData;
}

const stepOneValidationSchema = (t: any) =>
    Yup.object().shape({
        title: Yup.string().required(t('app:error.required')),
        holdings: Yup.array().of(
            Yup.object().shape({
                value: Yup.string().required(t('app:error.required')),
                label: Yup.string().required(t('app:error.required'))
            })
        ),
        publicationStartDate: Yup.string().required(t('app:error.required')).nullable(),
    });

const loadInitialValues = (savedData?: SavedData): formData => {
    if (savedData) {
        const [publicationStartDate, publicationEndDate]: Date[] = savedData.publicationRange ? savedData.publicationRange.split('/').map(date => new Date(date)) : [null, null];
        const mustHavePublicationEndDate = publicationEndDate ? publicationEndDate.getFullYear() !== 3000 : true;
        const languages: string[] = [];
        const articleTitle: LanguageStringContent = {};
        const articleResume: LanguageStringContent = {};
        const contents: LanguageArrayContent = {};
        const image = savedData.mainImage ? [{ id: savedData.mainImage.id }] : [{ id: '' }];

        for (const content of savedData.marketingCardContents) {
            if (!languages.includes(content.language)) {
                languages.push(content.language);
            }

            articleTitle[content.language] = content.contentTitle || '';
            articleResume[content.language] = content.resume || '';
            const contentBody: KeyContent[] = content.content || [];

            for (const contentItem of contentBody) {
                delete contentItem.__typename;
            }

            contents[`content${content.language.toUpperCase()}`] = contentBody;
        }

        return {
            languages,
            holdings: savedData.holdings ? savedData.holdings.map(holding => ({ label: holding.name, value: holding.id })) : [],
            title: savedData.title || '',
            type: savedData.type || '',
            idDraft: savedData.id,
            isDraft: savedData.isDraft,
            frArticleTitle: articleTitle.fr,
            frArticleResume: articleResume.fr,
            enArticleTitle: articleTitle.en,
            enArticleResume: articleResume.en,
            publicationStartDate,
            publicationEndDate: mustHavePublicationEndDate ? publicationEndDate : null,
            image,
            mustHavePublicationEndDate,
            contentEN: contents.contentEN,
            contentFR: contents.contentFR,
            linkToExternalPage: savedData.linkToExternalPage
        };
    }

    return {
        languages: ['fr'],
        holdings: [],
        title: '',
        type: MarketingTypeNew.ARTICLE,
        idDraft: undefined,
        isDraft: true,
        frArticleTitle: '',
        frArticleResume: '',
        enArticleTitle: '',
        enArticleResume: '',
        publicationStartDate: null,
        publicationEndDate: null,
        image: [{ id: '' }],
        mustHavePublicationEndDate: true,
        contentEN: [],
        contentFR: [],
        linkToExternalPage: null
    };
}

const CreateMarketingCard = ({ onSubmit, isModeEdit, onSubmitDraft, draftLabel, savedData }: IAddOrEditForm) => {
    const { t } = useTranslation();
    const [marketingCardType, setMarketingCardType] = useState(MarketingTypeNew.ARTICLE);
    const [stepTwoSchema, setStepTwoSchema] = useState();

    const marketingCardSteps = [
        {
            name: isModeEdit ? 'Edit Paramètres' : 'Paramètres',
            component: <StepOne isModeEdit={isModeEdit} validationSchema={stepOneValidationSchema(t)} setMarketingCardType={setMarketingCardType} />,
            validationFunctionsPerButtons: {
                draft: (props: FormikProps<FormikValues>) => {
                    return props.values.idDraft && !props.values.isDraft ? false : props.values.title;
                },
                next: (props: FormikProps<FormikValues>) => {
                    return (
                        props.values.languages.length &&
                        props.values.title &&
                        props.values.publicationStartDate &&
                        props.values.holdings &&
                        props.values.holdings.length &&
                        ((!props.values.mustHavePublicationEndDate && props.values.publicationEndDate === null) ||
                            (props.values.mustHavePublicationEndDate && props.values.publicationEndDate !== null))
                    );
                },
            },
        },
        {
            name: marketingCardType === MarketingTypeNew.IMPORTANT_MESSAGE ? 'Info news' :'Vignette',
            component: <StepTwo validationSchema={stepTwoSchema} setStepSchema={setStepTwoSchema} />,
            validationFunctionsPerButtons: {
                draft: (props: FormikProps<FormikValues>) => props.values.idDraft && !props.values.isDraft ? false : true,
                next: (props: FormikProps<FormikValues>) => {
                    const isTitleENValid = props.values.languages.includes('en') ? !!props.values.enArticleTitle : true;
                    const isTitleFRValid = props.values.languages.includes('fr') ? !!props.values.frArticleTitle : true;
                    const isResumeENValid = props.values.languages.includes('en') ? !!props.values.enArticleResume : true;
                    const isResumeFRValid = props.values.languages.includes('fr') ? !!props.values.frArticleResume : true;

                    // since it's optional - it's valid if it has no value or if the link is a valid url
                    const isLinkToExternalPageValid = !props.values.linkToExternalPage ? true : !props.errors.linkToExternalPage;

                    return marketingCardType === MarketingTypeNew.IMPORTANT_MESSAGE
                        ? props.values.image[0] &&
                              props.values.image[0].id &&
                              isTitleENValid &&
                              isTitleFRValid &&
                              isResumeENValid &&
                              isResumeFRValid &&
                              isLinkToExternalPageValid
                        : props.values.image[0] && props.values.image[0].id && isTitleENValid && isTitleFRValid;
                },
            },
        },
        {
            name: 'Article',
            component: <StepThree isModeEdit={isModeEdit}/>,
            validationFunctionsPerButtons: {
                draft: (props: FormikProps<FormikValues>) => props.values.idDraft && !props.values.isDraft ? false : true,
                next: (props: FormikProps<FormikValues>) => {
                    const selectedLanguages = props.values.languages.map(lang => lang.toUpperCase())
                    const arrContentValidations = selectedLanguages.map((lang) => {
                        if (!props.values[`content${lang}`]) return [false];

                        return props.values[`content${lang}`].map(({key, content})=> {
                            if(key === 'image' && content.length > 0){
                                return JSON.parse(content).length > 0
                                
                            } else if (key === 'infoNews') {
                                return content.includes('<div id="isValid"');
                            }
                            return content.length > 0;
                        });
                    });
                    // concat Arrays of Array into one and
                    // returns false if there is at least one false
                    const arrContentValidationsFlatten = arrContentValidations.flat()
                    return !!arrContentValidationsFlatten.length && arrContentValidationsFlatten.every(Boolean);
                },
            },
        },
    ];

    const initialValues = loadInitialValues(savedData);
    const steps = marketingCardType === MarketingTypeNew.ARTICLE ? marketingCardSteps : [marketingCardSteps[0], marketingCardSteps[1]];

    return (
        <MultiStep
            {...{ initialValues, steps }}
            onSubmit={onSubmit}
            backRoute={appRoutes.communication.list()}
            submitDraft={onSubmitDraft}
            draftLabel={draftLabel}
            continueButtonStyle={ButtonType.CONFIRMATION}
            nextLabel={t('app:button.continue')}
            finishLabel={t('app:button.publie')}
            withMargin={false}
            shouldReinitialize
        />
    );
};

export default CreateMarketingCard;
