import Modal from 'styled-react-modal'
import React from 'react'
import styled, { DefaultTheme } from 'styled-components';

import * as Icons from './Icons'
import { useTranslation } from 'react-i18next';

interface IProps {
    open: boolean;
    theme?: DefaultTheme;
    articleErrorLabels :string[];
    toggleModal: any;
}

export function CheckOfferValidModal(props: IProps) {
    const { t } = useTranslation();

    return (
        <StyledModal
            isOpen={props.open} >
            <StyledHeader>
                <div>{t("component:checkOfferValidModal.title")}</div>
                <StyledButton onClick={props.toggleModal}><Icons.Close theme={props.theme} color={"#2196F3"} height={39} /></StyledButton>
            </StyledHeader>
            <StyledBody>
                <StyledBodyIcon>
                    { props.articleErrorLabels.length > 0 && <Icons.Warning theme={props.theme} color={"#F76262"} width={53} height={53} />}
                    { props.articleErrorLabels.length === 0 && <Icons.RoundedCheck theme={props.theme} color={"#55DA98"} width={53} height={53} />}
                </StyledBodyIcon>
               
                <StyledBodyText>
                    { props.articleErrorLabels.length > 0 && <ErrorText labels={props.articleErrorLabels}/>}
                    { props.articleErrorLabels.length === 0 && <OkText/>}
                </StyledBodyText>
            </StyledBody>
        </StyledModal>
    )
}

function ErrorText(props : { labels : string[]; }) {
    const { t } = useTranslation();

    return (
        <StyledDiv>
            <span style={{color:'#F76262', fontWeight:"bold"}}>{t("component:checkOfferValidModal.errorText1")}</span>
            <br/>
            <br/>
            {props.labels.map(l => <div>{l}</div>)}
            <br/>
            <div>{t("component:checkOfferValidModal.errorText2")}</div>
            
        </StyledDiv>
    )
}

function OkText() {
    const { t } = useTranslation();

    return (
        <StyledDiv>
            <span style={{color:'#55DA98', fontWeight:"bold"}}>{t("component:checkOfferValidModal.okText1")}</span><span>{t("component:checkOfferValidModal.okText2")}</span>
    <div style={{fontWeight:"bold"}}>{t("component:checkOfferValidModal.okText3")}</div>
        </StyledDiv>
    )
}

const StyledDiv = styled.div`
    color: #5F7081;
    text-align: center;
`

const StyledModal = Modal.styled`
    height: 442px;
    width: 442px;
    border-radius: 4px;
    background-color: #FFFFFF;  
    display: flex;
    flex-direction : column;
    font-family: "Open Sans";
    font-size: 16px;
    letter-spacing: 0;
    line-height: 22px;
    justify-content: space-between;
`


const StyledHeader = styled.div`
  display: flex;
  flex-direction : row;
  border-radius: 4px 4px 0 0;
  background-color: #5F7081;
  height: 57px;
  color: #FFFFFF;
  font-weight: bold;
  justify-content: space-between;
  align-items: center;
  padding: 0 0 0 15px;
`

const StyledBody = styled.div`
  display: flex;
  flex-direction : column;
  justify-content: stretch;
  align-items:center;
  flex: 1;
  padding:40px;
`

const StyledBodyIcon = styled.div`
    height: 100px;
`

const StyledBodyText = styled.div`
`

const StyledButton = styled.button`
    background: none;
    border: 0px solid transparent;
    &:focus {
        outline: none;
    }
`;
