import { ApolloClient, InMemoryCache, from } from '@apollo/client';
import { setContext } from "@apollo/client/link/context";
import { onError } from '@apollo/client/link/error';
import { createUploadLink } from 'apollo-upload-client';
import DebounceLink from 'apollo-link-debounce';
import { getItem, LocalStorageKey } from 'services/persistentData';
import errorHandler from './errors';

const uploadLink = createUploadLink({
    uri: window.config.API_GRAPHQL_ENDPOINT,
});

// add debounceKey to query context to make debounce work
const DEFAULT_DEBOUNCE_TIMEOUT = 200;
const debounceLink = new DebounceLink(DEFAULT_DEBOUNCE_TIMEOUT);

const authLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists
    const token = getItem(LocalStorageKey.ACCESS_TOKEN);

    // return the headers to the context so httpLink can read them
    return {
        headers: {
            ...headers,
            authorization: token ? `Bearer ${token}` : '',
        },
    };
});

const errorLink = onError(errorHandler.onError);

export const client = new ApolloClient({
    link: from([
        debounceLink,
        errorLink,
        authLink,
        uploadLink
    ]),
    cache: new InMemoryCache(),
});
